import React, { useState, useEffect } from 'react';
import Navbar from '../components/nav';
import Aside from '../components/aside';
import Footer from '../components/footer';
import axios from 'axios';
import { useNavigate } from "react-router-dom";
import '../App.css';
import { ReactSession } from 'react-client-session';
ReactSession.setStoreType("localStorage");

 function App() {
    const navigate = useNavigate();
    const queryParameters = new URLSearchParams(window.location.search)
    const vendorId = queryParameters.get('id');
    const [name, setName] = useState('');
    const [phone, setPhone] = useState('');
    const [email, setEmail] = useState('');
    const [commi, setCommi] = useState('');
    const [address, setAddress] = useState('');
    const [state, setState] = useState('');
    const [newstate, setNewState] = useState('');
    const [password, setPassword] = useState('');
    const [pincode, sePincode] = useState('');
    const [file, setFile] = useState(null);
    const [error, setError] = useState('');

    const fetchData = async () => {

      try {
        const response = await axios.get('https://api.arivomkadai.com/addvendor/');
        if(response.data.status === 'success'){
            const vendor = response.data.data.filter(vendor=>vendor.id === vendorId);
            setName(vendor[0].name);
            setPhone(vendor[0].phonenumber);
            setEmail(vendor[0].email);
            setCommi(vendor[0].commission);
            setAddress(vendor[0].address);
            setState(vendor[0].state);
            setNewState(vendor[0].state);
            setPassword(vendor[0].password);
            sePincode(vendor[0].pincode);
        }
        
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    useEffect(() => {
      fetchData();
    }, []);

    const handleinputname = (event) => {
        setName(event.target.value);
    }

    const handleinputcommi = (event) => {
        setCommi(event.target.value);
    }

    const handleinputemail = (event) => {
        setEmail(event.target.value);
    }

    const handleinputaddress = (event) => {
        setAddress(event.target.value);
    }

    const handleinputphone = (event) => {
        setPhone(event.target.value);
    }

    const handleinputstate = (event) => {
        setState(event.target.value);
    }

    const handleinputnewstate = (event) => {
        setNewState(event.target.value);
    }


    const handleinputpincode = (event) => {
        sePincode(event.target.value);
    }

    const handleinputpass = (event) => {
        setPassword(event.target.value);
    }

    const handleFile = (event) => {
        setFile(event.target.files[0]); 
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
       
        let states = '';
        if(state === 'Other States'){
            states = newstate;
        }else{
            states = state;
        }

        const formData = new FormData();

        formData.append('name', name);
        formData.append('email', email);
        formData.append('phone', phone);
        formData.append('address', address);
        formData.append('state', states);
        formData.append('pincode', pincode);
        formData.append('image', file);
        formData.append('pass', password);
        formData.append('commi', commi);
        formData.append('id', vendorId);
        
        try {
           
            const response = await axios.post('https://api.arivomkadai.com/editvendor/', formData);
            console.log("Response data:", response.data);
            if(response.data.status === 'success'){
              navigate('/view-vendor');
            }else{
                alert('Unable to update vendoe details!');
            }
            
        } catch (error) {
            // Handle error
            console.error('There was a problem with login:', error);
            setError('Invalid email or password');
        }
       // closeModal();
    };

      
  return (
    <div className="layout-wrapper layout-content-navbar">
        <div className="layout-container">
            <Aside />
                <div className="layout-page">
                    <Navbar />
                        <div className="content-wrapper">

                            <div class="container-xxl flex-grow-1 container-p-y">
                                <div class="row">
                                    <div class="col-md-6 col-lg-12 col-xl-12  mb-4">
                                        <div class="card pb-4 ">
                                            <div class="card-header d-flex align-items-center justify-content-between pb-0">
                                                <div class="card-title mb-0 justify-content-center">
                                                    <h4 class="m-0 me-2 mb-4 justify-content-center">Edit Vendor Details</h4>
                                                </div>
                 
                                            </div>
                                            <div class="card-body">

                                            <form id="formAccountSettings"  onSubmit={handleSubmit}>
                                            <div className="row">
                                                <div className="mb-3 col-md-3">
                                                    <label htmlFor="link" className="form-label">Name</label>
                                                    <input
                                                        className="form-control"
                                                        type="text"
                                                        id="name"
                                                        name="name"
                                                        value={name}
                                                        onChange={handleinputname}
                                                    />
                                                    
                                                </div>

                                                <div className="mb-3 col-md-3">
                                                    <label htmlFor="link" className="form-label">Phone Number</label>
                                                    <input
                                                        className="form-control"
                                                        type="text"
                                                        id="phone"
                                                        name="phone"
                                                        value={phone}
                                                        onChange={handleinputphone}
                                                    />
                                                    
                                                </div>


                                                <div className="mb-3 col-md-3">
                                                    <label htmlFor="link" className="form-label">Email</label>
                                                    <input
                                                        className="form-control"
                                                        type="email"
                                                        id="email"
                                                        name="email"
                                                        value={email}
                                                        onChange={handleinputemail}
                                                      
                                                    />
                                                    
                                                </div>

                                                <div className="mb-3 col-md-3">
                                                    <label htmlFor="link" className="form-label">Commission %</label>
                                                    <input
                                                        className="form-control"
                                                        type="number"
                                                        id="email"
                                                        name="email"
                                                        value={commi}
                                                        onChange={handleinputcommi}
                                                      
                                                    />
                                                    
                                                </div>

                                               
                                                <div className="mb-3 col-md-3">
                                                    <label htmlFor="des" className="form-label">Password</label>
                                                    <input
                                                        className="form-control"
                                                        type="State"
                                                        name="password"
                                                        id="classic-editor"
                                                        value={password}
                                                        onChange={handleinputpass}
                                                        
                                                    />
                                                </div>
                                                
                                                
                                                {state !== 'Other States' ? (

                                                <div className="mb-3 col-md-3">
                                                    <label htmlFor="des" className="form-label">State</label>
                                                    <select class="form-control" required value={state} onChange={handleinputstate}>
                                                        <option value="" disabled selected hidden>Select State</option>
														<option value="Tamil Nadu">Tamil Nadu</option>
														<option value="Kerala">Kerala</option>
														<option value="Andhra Pradesh">Andhra Pradesh</option>
														<option value="Karnataka">Karnataka</option>
														<option value="Other States">Other States</option>
                                                    </select>
                                                </div>
                                                ):(

                                                <div className="mb-3 col-md-3">
                                                    <label htmlFor="des" className="form-label">State</label>
                                                    <input
                                                        className="form-control"
                                                        type="State"
                                                        name="State"
                                                        id="classic-editor"
                                                        required
                                                        value={newstate}
                                                        placeholder='Enter state...'
                                                        onChange={handleinputnewstate}
                                                        
                                                    />
                                                </div>

                                                )}


                                                <div className="mb-3 col-md-3">
                                                    <label htmlFor="des" className="form-label">Pincode</label>
                                                    <input
                                                        className="form-control"
                                                        type="pincode"
                                                        name="pincode"
                                                        id="classic-editor"
                                                        value={pincode}
                                                        onChange={handleinputpincode}
                                                        
                                                    />
                                                </div>


                                                <div className="mb-3 col-md-3">
                                                    <label htmlFor="image" className="form-label">Image</label>
                                                    <input
                                                      className="form-control"
                                                      type="file"
                                                      name="image"
                                                      id="image"
                                                      accept="image/png, image/jpeg, image/jpg, image/pdf"
                                                      onChange={handleFile}
                                                    />
                                                 </div>
                                                
                                                 <div className="mb-3 col-md-12">
                                                    <label htmlFor="des" className="form-label">Address</label>
                                                    <textarea
                                                        className="form-control"
                                                        type="text"
                                                        name="address"
                                                        id="address"
                                                        value={address}
                                                        onChange={handleinputaddress}
                                                    />
                                                </div>
                                                
                                                
                                                
                                                
                                            </div>
                                            <div className="mt-2" align="center">
                                                <button type="submit" className="btn btn-primary me-2 w-25">Save Data</button>
                                            </div>
                                        </form>
     
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        <Footer />
                    <div className="content-backdrop fade"></div>
                </div>
            </div>
        </div>
        <div className="layout-overlay layout-menu-toggle"></div>
    </div>
  )
}
export default  App;
