import React, { useState, useEffect } from 'react';
import Navbar from '../components/nav';
import Aside from '../components/aside';
import Footer from '../components/footer';
import axios from 'axios';
import { Paginator } from 'primereact/paginator';
import { useNavigate } from "react-router-dom";
import { ReactSession } from 'react-client-session';
import '../App.css';
ReactSession.setStoreType("localStorage");

 function Stockmaintance() {
    const userID = ReactSession.get('userId');
 // console.log(userID);
    const [datas, setData] = useState([]);
    const [first, setFirst] = useState(0); // Index of the first row on the current page
    const [rows, setRows] = useState(10); // Display only one row per page
    const navigate = useNavigate();
    const [searchQuery, setSearchQuery] = useState('');
    const [filteredData, setFilteredData] = useState(datas.data);
  
    // Function to handle search input change
    const handleSearchInputChange = (event) => {
      const query = event.target.value;
      setSearchQuery(query);
  
      // Filter datas.data based on search query
      const filtered = datas.data.filter(item =>
        item.product.toLowerCase().includes(query.toLowerCase()) ||
        item.category.toLowerCase().includes(query.toLowerCase())
      );
      setFilteredData(filtered);
    };
  
    if(userID <= 0 ){
      navigate('/login');
   }


   
 const openModal = () => {
  const modal = document.getElementById('onloadModal');
  if (modal) {
      modal.classList.add('show');
      modal.style.display = 'block';
      document.body.classList.add('modal-open');
  }
};

const closeModal = () => {
  const modal = document.getElementById('onloadModal');
  if (modal) {
      modal.classList.remove('show');
      modal.style.display = 'none';
      document.body.classList.remove('modal-open');
  }
};


const fetchData = async () => {
  openModal();
  try {
    const response = await axios.get('https://api.arivomkadai.com/offlineproduct/');
    setData(response.data);
  } catch (error) {
    console.error('Error fetching data:', error);
  }
  closeModal();
};



    useEffect(() => {
      fetchData();
    }, []);
  
    const removeProducts = async (productId) => {
      //console.log('Deleting product with ID:', productId); // Log the product ID
      try {
        openModal();
        const response = await axios.post('https://api.arivomkadai.com/deleteofflineproduct/', { id: productId });
       // console.log("delete:", response.data);
        fetchData();
      } catch (error) {
        console.error('Error deleting product:', error);
      }
      closeModal();
    };
  
    const onPageChange = (event) => {
      setFirst(event.first);
      setRows(10); // Update rows to display only one row per page
    };
  


    




    return (
      <>
      <div id="onloadModal" >
      <div class="modal-content"  align='center'>
          <p style={{padding:'20px'}}>Product Loading.</p>
      </div>
       </div>
      <div className="layout-wrapper layout-content-navbar">
        <div className="layout-container">
          <Aside />
          <div className="layout-page">
            <Navbar />
            <div className="content-wrapper">

            


            
              <div className="container-xxl flex-grow-1 container-p-y">
                <div className="card">
            <h5 className="card-header">Arivomkadai Stock Maintance</h5>
            <div className="demo-inline-spacing" style={{ display: 'flex', justifyContent: 'flex-end', marginTop: '-50px' }}>
          <div className="col-lg-3 ml-2">
            <input
              type="text"
              className="form-control"
              placeholder="Search by product name or category..."
              value={searchQuery}
              onChange={handleSearchInputChange}
            />
          </div>
            </div>
            
            <div className="table-responsive text-nowrap mt-2">
              <table className="table table-hover" width="100%">
                <thead>
                  <tr>
                    <th width="5%">Image</th>
					<th  width="5%">category</th>
          <th width="5%">Product</th>
                    <th width="5%">Stock</th>
                    <th width="5%">Actions</th>
                  </tr>
                </thead>
                <tbody className="table-border-bottom-0">
                {searchQuery && filteredData.length > 0 ? (
            <>  {filteredData.map(item => (
                    <tr key={item.p_id}>
                    <td>


                      
                      {item.image ? (
                        <img src={`https://api.arivomkadai.com/product_data/${item.image}`} alt="Avatar" className="w-px-50 h-px-80 rounded-circle" />
                      ) : (
                        <img  src={'https://api.arivomkadai.com/product_data/no_image.webp'}  alt="No Image" className="w-px-50 h-px-80 rounded-circle" />
                      )}
                    </td>
                    <td>{item.category}</td>
                    <td className="text-wrap text-break">{item.product.length > 40 ? `${item.product.slice(0, 40)}...` : item.product}</td>
                  
                    <td>{item.stock+' '+item.stockType}</td>
                    <td><a  href={`editstock?id=${item.p_id}`} class='btn btn-primary bg-gradient-primary'> <i class="menu-icon tf-icons bx bx-edit" style={{color:'#fff'}}></i></a>
                    </td>
                  </tr>
                   ))}
                   </>
                 ) : searchQuery && filteredData.length === 0 ? (
   <tr >
                <td>No products found </td>
              </tr>
) : (<>
              
                  {datas && datas.data && datas.data.slice(first, first + rows).map(item => (
                    <tr key={item.p_id}>
                      <td>


                        
                        {item.image ? (
                          <img src={`https://api.arivomkadai.com/product_data/${item.image}`} alt="Avatar" className="w-px-50 h-px-80 rounded-circle" />
                        ) : (
                          <img  src={'https://api.arivomkadai.com/product_data/no_image.webp'}  alt="No Image" className="w-px-50 h-px-80 rounded-circle" />
                        )}
                      </td>
					  <td>{item.category}</td>
            <td className="text-wrap text-break">{item.product.length > 40 ? `${item.product.slice(0, 40)}...` : item.product}</td>
                  
                    
                      <td>{item.stock+' '+item.stockType}</td>
                      <td><a  href={`editstock?id=${item.p_id}`} class='btn btn-primary bg-gradient-primary'> <i class="menu-icon tf-icons bx bx-edit" style={{color:'#fff'}}></i></a>
                      </td>
                    </tr>
                  ))}
                  </>)}
                </tbody>
              </table>
              <Paginator className="custom-paginator" first={first} rows={rows} totalRecords={datas.data ? datas.data.length : 0}  onPageChange={onPageChange} />
             
              
               </div>
          </div>
              </div>
              <Footer />
              <div className="content-backdrop fade"></div>
            </div>
          </div>
        </div>
        <div className="layout-overlay layout-menu-toggle"></div>
      </div>
    </>
  )
}
export default Stockmaintance;
