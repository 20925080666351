import React, { useState, useEffect } from 'react';
import Navbar from '../components/nav';
import Aside from '../components/aside';
import Footer from '../components/footer';
import axios from 'axios';
import { Paginator } from 'primereact/paginator';
import { useNavigate } from "react-router-dom";
import '../App.css';
import { ReactSession } from 'react-client-session';
ReactSession.setStoreType("localStorage");



function Offlineorder  () {
    const userID = ReactSession.get('userId');
    const [first, setFirst] = useState(0); 
    const [rows, setRows] = useState(10);
    const [order, setOrder] = useState([]);
    const navigate = useNavigate();
    const [searchQuery, setSearchQuery] = useState('');
    const [filteredData, setFilteredData] = useState(order.data);
    const [awno, setAwn] = useState(0);

    const [selectedItem, setSelectedItems] = useState([]);
    const [bulk, setBulk] = useState('');
    const [bulkStatuses, setBulkStatus] = useState('');
    const [labelData, setLabeklData] = useState([]);
    const [totalOrders, setTotalOrders] = useState(0);
    const [nextButton, setNext] = useState(true);
    const [searchFrom, setSearchQueryFrom] = useState('');
    const [searchStart, setSearchStart] = useState(false);

    

    const searchBulk = (query) =>{
      const filtered = order.filter(item =>
        item.order_id.toLowerCase().includes(query.toLowerCase()) ||
        item.date.toLowerCase().includes(query.toLowerCase()) ||
	      item.name.toLowerCase().includes(query.toLowerCase()) ||
	      item.number.toLowerCase().includes(query.toLowerCase()) ||
        item.status.toLowerCase().includes(query.toLowerCase())
      );
      setFilteredData(filtered);
    };

    if(userID <= 0 ){
      navigate('/login');
    }

     const fetchData = async () => {
      try {
        const response = await axios.get('https://api.arivomkadai.com/offlineorders/');
        if(response.data.status === 'success'){
          setTotalOrders(response.data.orderCount);
	        const paid = response.data.data.filter(item =>item.payment_status ==='paid');
          setOrder(paid); 
          setSearchStart(false);
        }
       
	      //console.log("orders:", response.data);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    const handleSearchInputsChange = (event) =>{
      const query = event.target.value;
      setSearchQueryFrom(query);
    };

    const handleSearchInputChange = (event) => {
      const query = event.target.value;
      setSearchQuery(query);
    };

    const searchNow = async () => {
      if(searchFrom === ''){
        alert('Select Search option');
      }else{
        if(searchQuery.length > 0){
          const sendData = {query: searchQuery, from: searchFrom};
          try{
            const response = await axios.post('https://api.arivomkadai.com/search-offline-orders/', sendData);
            console.log(response.data);
            if(response.data.status === 'success'){
              setTotalOrders(response.data.orderCount);
	            const paid = response.data.data.filter(item =>item.payment_status ==='paid');
              setOrder(paid); 
              setSearchStart(true);
            }else{
              setOrder([[]]); 
            }
          }catch(error){
            console.error(error);
          }
        }else{
          fetchData();
        }
      }
    };

    const clearSearch = () => {
      fetchData();
    };

    const fetchNextData = async (offset) => {
      const next = {next: offset}
      try {
        const response = await axios.post('https://api.arivomkadai.com/offlineorders/', next);
        if(response.data.status === 'success'){
          setTotalOrders(response.data.orderCount);
	        const paid = response.data.data.filter(item =>item.payment_status ==='paid');
          setOrder(paid); 
        }
       
	      //console.log("orders:", response.data);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    const awNo = async() => {
      try {
        const response = await axios.get('https://api.arivomkadai.com/stcourier/');
        setAwn(response.data.awno);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    useEffect(() => {
      if(nextButton){
        fetchData();
      }
      awNo();
    }, []);

    // useEffect(() => {
    //   fetchNextData(first);
    //   const intervalId = setInterval(() => {
    //     fetchNextData(first);
    //   }, 5000);
    //   return () => clearInterval(intervalId);
    // }, []);

  

    const onPageChange = (event) => {
      setNext(false);
      setFirst(event.first);
      setRows(10);
      fetchNextData(event.first);
      
    };


    //-----------Popup Start-----------//

    const openModal = (content) => {
      const modal = document.getElementById('onloadModal');
      if (modal) {
          modal.classList.add('show');
          modal.style.display = 'block';
          document.body.classList.add('modal-open');
          const contentElement = document.getElementById('modalContent');
          contentElement.textContent = content;
      }
    };
    
    const closeModal = () => {
      const modal = document.getElementById('onloadModal');
      if (modal) {
          modal.classList.remove('show');
          modal.style.display = 'none';
          document.body.classList.remove('modal-open');
      }
    };

    

    //-----------Popup End-----------//


    //---------bulk process start-----------//

    const toggleCheckbox = (itemId) => {
      setSelectedItems(prevItems => {
        if (prevItems.includes(itemId)) {
          return prevItems.filter(id => id !== itemId);
        } else {
          return [...prevItems, itemId];
        }
      });
    };

    const toggleAllCheckbox = () => {
      if (searchQuery) {
        if (filteredData.length > 0) {
          const allIds = filteredData.map(item => item.order_id);
          const allSelected = allIds.every(id => selectedItem.includes(id));
          setSelectedItems(prevItems => {
            if (allSelected) {
              return prevItems.filter(id => !allIds.includes(id));
            } else {
              return [...new Set([...prevItems, ...allIds])];
            }
          });
        } else {
          alert('No Orders to select');
        }
      } else {
        const itemsToProcess = Array.isArray(order) ? order.slice().reverse().slice(first, first + rows) : [];
        const allIds = itemsToProcess.map(item => item.order_id);
        const allSelected = allIds.every(id => selectedItem.includes(id));
        setSelectedItems(prevItems => {
          if (allSelected) {
            return prevItems.filter(id => !allIds.includes(id));
          } else {
            return [...new Set([...prevItems, ...allIds])];
          }
        });
      }
    };
    

    const bulkOption = (event) =>{
      setBulk(event.target.value);
    };

    const bulkStatus = (event) =>{
      setBulkStatus(event.target.value);
    };

    const bulkProcess = async ()  =>{
      openModal(`Updating Status of ${selectedItem.length} orders..`);
      const bulkData = {
        ids: selectedItem,
        status: bulkStatuses
      }
      try {
        const response = await axios.post('https://api.arivomkadai.com/bulk-process/', bulkData);
        //console.log(response.data);
        if(response.data.status === 'success'){
          fetchNextData(first);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    const bulkPrint = () => {
      const data = order.filter(item => selectedItem.includes(item.order_id));
      setLabeklData(data);
    };

    useEffect(()=>{
      if(bulk === 'label'){
        bulkPrint();
      }
    },[bulk]);

    //---------bulk process End-----------//
    
    const handlePrint = () => {
      // Create a new window for printing
      const printWindow = window.open('', '', 'width=800,height=600');
    
      // Check if the window opened successfully
      if (!printWindow) {
        console.error('Failed to open print window.');
        return;
      }
    
      // Add styles and content to the new window
      printWindow.document.write('<html><head><title>Print</title>');
      printWindow.document.write('<style>body { font-family: Arial, sans-serif; margin: 20px; }</style>'); // Optional: Add styles
      printWindow.document.write('</head><body>');
    
      // Get the content to print
      const printContent = document.getElementById('printable-content').innerHTML;
      printWindow.document.write(printContent);
    
      printWindow.document.write('</body></html>');
      printWindow.document.close(); // Necessary for IE >= 10
    
      // Ensure the content is fully loaded before calling print
      setTimeout(() => {
        printWindow.focus(); // Necessary for IE >= 10
        printWindow.print();
        printWindow.close(); // Optionally close the window after printing
        setLabeklData([]);
      }, 500); // Adjust the delay as needed
    };
    



useEffect(()=>{
  if(order.length === 0){
    openModal(`Fetching orders please wait...`);
  }else{
    closeModal();
  }
},[order]);
    return (
      <>

      <div id="onloadModal" >
        <div class="modal-content"  align='center'>
          <p style={{padding:'20px'}} id="modalContent">Updating Status of {selectedItem.length} orders..</p>
        </div>
      </div>

      <div  id="printable-content" align="center" style={{margin: '0', padding: '0'}}>
      {labelData.length > 0 && labelData.map(orders => (
          <div  style={{padding:'5px', maxWidth: '364px', maxHeight: '530px', minWidth: '364px', minHeight: '530px', background: '#fff', overflow: 'hidden', alignItems: 'center', justifyContent: 'center', marginLeft:'auto', marginRight:'auto', marginTop: '5px'}}>
          <div style={{ textAlign:'center', minWidth: '352px', minHeight: '526px', border: '3px solid #000', borderRadius: '10px', overflow: 'hidden'}} >
          <img style={{margin: '3px'}} src="logo.png" alt="logo" width="80%" />
            
            <div style={{background: '#000', marginBottom: '-10px', justifyContent: 'center', alignItems: 'center'}}>
                <p style={{fontWeight: 'bold', color: '#fff', fontSize: '12px', padding: '5px 0px'}} >5D, CHITHRA NAGAR, SARAVANAMPATTI, CBE - 641035</p>
            </div>

            <div style={{background: '#000', marginBottom: '-10px', justifyContent: 'center', alignItems: 'center'}}>
                <p style={{fontWeight: 'bold', color: '#fff', fontSize: '12px', padding: '5px 0px'}} >CUSTOMER CARE NUMBER :   +91 81229 30840 <br></br> G-Pay : 9043842020</p>
            </div>

            {/* <div style={{background: '#000', marginBottom: '-10px', justifyContent: 'center', alignItems: 'center'}}>
                <p style={{fontWeight: 'bold', color: '#fff', fontSize: '12px', padding: '5px 0px'}} >ORDER NUMBER : {orders.order_id} <br />Order Value : Rs. {Number( orders.grand_total ).toFixed(2)}</p>
            </div> */}
          
            
            <div style={{ padding: '0px 20px', marginBottom: '10px', textAlign: 'left'}}>
                <h6 style={{fontWeight: 'bold', color: '#000', fontSize: '20px', paddingTop:'5px'}} >{orders.name}</h6>
                <p style={{color: '#000', fontSize: '17px', fontWeight: 'bold', marginTop: '-10px'}}>
                    {orders.buiulding} <br />
                    {orders.city} , {orders.state} - {orders.pincode}<br />
                    Landmark : {orders.landmark}
                </p>
                <p style={{color: '#000', fontSize: '18px', fontWeight: 'bold', marginTop: '-8px'}}>
                Contact : {orders.number}
                </p>
                <hr style={{color: '#000', height: '1px', background: '#000'}}></hr>
                
                <p style={{fontSize: '14px', color: '#000', marginTop: '10px', fontWeight: 'bold'}}> Order Details ( {orders.count} )</p>
                {orders && orders?.orderData ? (
                orders.orderData.map((item, index) => (
                <p style={{marginTop: '-5px', fontSize: '12px', color: '#000', fontWeight: 'bold'}}>{index+1}. {item.product} x {item.quantity}</p>
                ))
                ) : (
                <>No order data available</>
                )}
            
            </div>
            
            
            </div>
        </div>
       ))}

      </div>

      

     <div className="layout-wrapper layout-content-navbar">
      <div className="layout-container">
       <Aside />
       <div className="layout-page">
        <Navbar />
        <div className="content-wrapper">
    
        <div class="container-xxl flex-grow-1 container-p-y">
        <div class="row">
        <div class="col-lg-8 mb-4 order-0">
                <div class="card">
                  <div class="d-flex align-items-end row">
                    <div class="col-sm-7">
                      <div class="card-body">
                      <h5 class="card-title">ArivomKadai</h5>
                     
                        <p class="mb-4">
                          <span class="fw-bold">Arivomkadai:</span> Bridging the Gap Between Online and Arivomkadai Excellence products.
                        </p>
                      </div>
                    </div>
                    <div class="col-sm-5 text-center text-sm-left">
                      <div class="card-body pb-0 px-0 px-md-4">
                        <img
                          src="../assets/img/illustrations/man-with-laptop-light.png"
                          height="140"
                          alt="View Badge User"
                          data-app-dark-img="illustrations/man-with-laptop-dark.png"
                          data-app-light-img="illustrations/man-with-laptop-light.png"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              
              <div class="col-md-6 col-lg-4 col-xl-4 order-0 mb-4">
                <div class="card pb-4">
                  <div class="card-header d-flex align-items-center justify-content-between pb-0">
                    <div class="card-title mb-0">
                      <h5 class="m-0 me-2 mb-3">Arivomkadai Orders</h5>
                      <span>Total Orders</span><br />
                    </div>
                    <div class="avatar flex-shrink-0">
                      <img src="../assets/img/icons/unicons/cc-primary.png" alt="Credit Card" class="rounded" />
                    </div>
                    
                  </div>
                  <div class="card-body">
                    <div class="d-flex justify-content-between align-items-center">
                      <div class="d-flex flex-column align-items-center gap-1">
                        <h4 class="mb-2">{order.length > 0 ? order.length : 'Loading...'}</h4>
                      </div>   </div>
                  </div>
                </div>
              </div>




              </div>


              
            
          
       
           <div className="card">
          <h5 className="card-header">Arivomkadai Orders</h5><br></br>
          

          <div className="row ml-1 mr-1" >
          {selectedItem.length > 0 && 
           
            <div className="col-lg-3 col-md-3 col-6 col-xl-3 col-xxl-3">
             <select className="form-control" value={bulk} onChange={bulkOption} >
              <option value="" hidden >With Selected</option>
              <option value="label" >Print Label</option>
              <option value="status">Update Status</option>
             </select>
            </div>
            
          } 

          { bulk === 'status' && 
           
            <div className="col-lg-3 col-md-3 col-6 col-xl-3 col-xxl-3">
             <select className="form-control" value={bulkStatuses} onChange={bulkStatus} >
              <option value="" hidden >Select Status</option>
              <option value="Confirm">Confirm</option> 
							<option value="Dispatched">Shipped</option> 
						  <option value="Delivered">Delivered</option> 
							<option value="Cancelled">Cancelled</option> 
             </select>
            </div>
         
          }

          { bulk === 'label' &&
            <div className="col-lg-3 col-md-3 col-6 col-xl-3 col-xxl-3">
              <button class='btn btn-primary w-100' onClick={handlePrint}>Print Label</button>
            </div>
          }
          { bulk !== 'label' && bulkStatuses !== '' &&
            <div className="col-lg-3 col-md-3 col-6 col-xl-3 col-xxl-3">
              <button class='btn btn-primary w-100' onClick={bulkProcess}>Update Status</button>
            </div>
          }
            
            <div className="col-lg-3 col-md-3 col-6 col-xl-3 col-xxl-3 mt-1">
              <select
                className="form-control"
                value={searchFrom}
                onChange={handleSearchInputsChange}
              >
                <option value='' hidden>Select Option to Search</option>
                <option value="order_id">Order Id</option>
                <option value="phonenumber">Phone</option>
                <option value="name">Name</option>
              </select>
            </div>
            <div className="col-lg-3 col-md-3 col-6 col-xl-3 col-xxl-3 mt-1">
              <input
                type="text"
                className="form-control"
                placeholder="Search..."
                value={searchQuery}
                onChange={handleSearchInputChange}
              />
            </div>
            <div className="col-lg-3 col-md-3 col-6 col-xl-3 col-xxl-3">
              <button class="btn btn-warning" style={{marginRight: '20px'}} onClick={searchNow}>Search</button>
              {searchStart && <button class="btn btn-danger" onClick={clearSearch}>Clear Search</button> }
            </div>
            
          </div>


          <div className="table-responsive text-nowrap mt-2 mb-4">
            <table className="table table-hover">
              <thead>
                <tr> 
                  <th>{selectedItem.length > 0 ? selectedItem.length+' Seleced' : <input type="checkbox" checked={selectedItem.length > 0 ? true:false} onChange={() => toggleAllCheckbox()}/>}</th>
                  <th>customer</th>
                  <th>order id</th>
                  <th>Date</th>
                  <th>Phonenumber</th>
                  <th>status</th>
                  <th>items</th>
                  <th>Amount</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody className="table-border-bottom-0">
           
                {Array.isArray(order) && order.map(item => (
				
                  <tr key={item.order_id}>
                    <td> <input type="checkbox" checked={selectedItem.includes(item.order_id) ? true : false}  onChange={() => toggleCheckbox(item.order_id)} /></td>
                    <td>
                      {item.profile ? (
                        <img src={`https://api.arivomkadai.com/customer_data/${item.profile}`} alt={item.name} className="w-px-40 h-px-40 rounded-circle" />
                      ) : (
                        <img  src={'https://api.arivomkadai.com/customer_data/no_image.webp'}  alt="No Image" className="w-px-40 h-px-40 rounded-circle" />
                      )}
                       <span class="fw-medium ml-1">{item.name} {item.read === 'yes' && <span style={{marginLeft: '10px', background: 'red', color: '#fff', padding: '3px 7px', borderRadius: '20px', fontSize: '10px', fontWeight: 'bold'}}>New</span> }</span>
                    </td>
                    <td>{item.order_id}</td>
                    <td>{item.date}</td>
                    <td>{item.number}</td>
                    <td><span className="badge bg-label-primary me-1 text-wrap" style={{ display: 'inline-block', lineHeight: 1.5 }}>{item.status}</span></td>
                    <td>{item.count}</td>
                    <td>₹ {item.grand_total}</td>
                    <td><a href={`order_details?id=${item.order_id}`} class="btn btn-sm btn-outline-primary">View</a>
                         </td>
                  </tr>
				 
                ))}
               
              </tbody>
            </table>
            <div align="center" style={{textAlign: 'center'}}>
              <Paginator className="custom-paginator"  first={first} rows={rows} totalRecords={totalOrders}  onPageChange={onPageChange} />
            </div>
           
             
             </div>
        </div>
    
          </div>


          
          <Footer />
          <div className="content-backdrop fade"></div>
        </div>
      </div>
    </div>
    <div className="layout-overlay layout-menu-toggle"></div>
  </div>
  </>
  );
};

export default Offlineorder;
