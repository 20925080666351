import React, { useState, useEffect } from 'react';
import Navbar from '../components/nav';
import Aside from '../components/aside';
import Footer from '../components/footer';
import axios from 'axios';
import { useNavigate } from "react-router-dom";
import '../App.css';
import { ReactSession } from 'react-client-session';
ReactSession.setStoreType("localStorage");

 function Order_details() {
    const userID = ReactSession.get('userId');
   
    const [status, setStatus] = useState('');
    const [process, setProcess] = useState(null);
    const queryParameters = new URLSearchParams(window.location.search)
    const orderId = queryParameters.get("id");
    const [currentOrderId, setCurrentOrderId] = useState(orderId);
    
    const [prevOrderId, setPrevOrderId] = useState(null);
    const [nextOrderId, setNextOrderId] = useState(null);
    const [orders, setOrders] = useState([]);
    const [readStaus, setRead] = useState(false);
    const [track, settrack] = useState(null);
    const [courier, setcourier] = useState(null);
    const navigate = useNavigate();

    if(userID <= 0 ){
        navigate('/login');
     }

    const updatestatus = async () => {

      const formData = new FormData();
      formData.append('id', currentOrderId);
      formData.append('status', status);

      try {
        const response = await axios.post('https://api.arivomkadai.com/order_status/', formData);
       // console.log(response.data);
        if (response.data.status === 'success') {
          alert('Order Status Updated');
        }
      } catch (error) {
        console.error('Status Error:', error);
      }
    };
 
   

    const fetchDatas = async () => {
      const orderData = {orderId: currentOrderId}
      try {
        const response = await axios.put('https://api.arivomkadai.com/offlineorders/', orderData);
        //console.log(response.data);
        if(response.data.status === 'success'){
          setOrders(response.data.data);
          const filteredOrders = response.data.data.filter(order => order.order_id === currentOrderId);
          setProcess(filteredOrders);
          setStatus(filteredOrders[0].status);
          setPrevOrderId(response.data.nextPrev.prev);
          setNextOrderId(response.data.nextPrev.next);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    useEffect(() => {
      fetchDatas();
    }, [currentOrderId]);
 
    const stCourier = async () => {
  
    const formData = new FormData();
    formData.append('order_id', orderId);
    formData.append('order_from', 'offline');
 
    try {
      const response = await axios.post('https://api.arivomkadai.com/stcourier/', formData); 
      if(response.data.id){
        alert(response.data.id);
      }else{
        alert('Waiting for response!');
      }
      fetchDatas();
      
    } catch (error) {
      console.error('Status Error:', error);
    }
    fetchDatas();
  };
	 
  useEffect(() => {
    if(process && process[0]?.read === 'yes' && readStaus === false){
      updateRead();
    }
  },[orders, process]);



  const updateRead = async () => {
    try {
      const response = await axios.post('https://api.arivomkadai.com/read-order/', {orderId : currentOrderId}); 
      if(response.data.status === 'success'){
        setRead(true);
      }
      //console.log(response.data);
    } catch (error) {
      console.error('Status Error:', error);
    }
  };



  const handleSubmit = async(event) => {
    event.preventDefault();
    const formData = {
        orderId : currentOrderId,
        trackId: track,
        courier: courier
    }
    //console.log(formData);
    try {
      const response = await axios.post('https://api.arivomkadai.com/set-courier/', formData); 
      if(response.data.status === 'success'){
        setRead(true);
        fetchDatas();
        alert('Tracking Details Updated & Shared with Customer!');
      }
      //console.log(response.data);
    } catch (error) {
      console.error('Status Error:', error);
    }
  };
  
  
  return (
    <div className="layout-wrapper layout-content-navbar">
    <div className="layout-container">
      <Aside />
      <div className="layout-page">
        <Navbar />
        <div className="content-wrapper">
    { process && process.length > 0 ? 
        <div class="container-xxl flex-grow-1 container-p-y">
        <div class="row">
        <div class="col-lg-12">
          <div class="card mb-4">

            
            <div class="card-header">
            

              <div class="row">
              <div class="col-lg-4">
              <h5 class="card-title mb-3">Order Details</h5>
                <p class="">
          Order ID : {process && process[0]?.order_id} <br />
          Invoice ID : #000{process && process[0]?.invoiceNumber}<br />
          Date : {process && process[0]?.date}
        </p>
            <h6><b>Items : {process && process[0]?.count}</b></h6>      
            <h6><b>Amount : ₹ {Number(process && process[0]?.grand_total).toFixed(2)}</b></h6>      
                
              </div>
              <div class="col-lg-8">
              <h5 class="mb-3">Shipping Details</h5>
      
      <p class=" mb-2"><b>{process && process[0]?.name}</b></p>
      <p class=" mb-1"> {process && process[0]?.buiulding}, {process && process[0]?.street}, {process && process[0]?.city}, {process && process[0]?.state} - {process && process[0]?.pincode}</p>
      <p class=" mb-4">{process && process[0]?.landmark}</p>
     
      <p class=" mb-1"><i class="fa fa-envelope"></i> {process && process[0]?.email}</p>
      <p class=" mb-1"><i class="fa fa-phone"></i> {process && process[0]?.number}</p>
        </div>
        </div>
       
       
            </div>
          </div>
        </div>

        <div class="col-lg-8 mb-4 order-0">
                
    <div class="card mb-4">
      <div class="card-header d-flex justify-content-between align-items-center">
        <h5 class="card-title m-0">Cart Details</h5>
        
      </div>
      <div class="card-datatable table-responsive">
        <table class="datatables-order-details table">
          <thead>
            <tr>
              <th></th>
              <th class="w-50">Products</th>
              <th class="w-25">Price</th>
              <th class="w-25">QTY</th>
              <th>Amount</th>
            </tr>
          </thead>
          <tbody>
          {process && process[0]?.orderData && process[0].orderData.map(product => (
        
        <tr class="odd">
          <td class="  control" tabindex="0" style={{display: 'none'}} key={product.id}></td >
          <td class="sorting_1">
              <div class="d-flex justify-content-start align-items-center text-nowrap">
                <div class="avatar-wrapper">
                  <div class="avatar me-2">
                    <img src={`https://api.arivomkadai.com/product_data/${product.img}`} alt={product.product} class="rounded-2"/>
                  </div>
                </div>
              </div>
            </td>
            <td><span>{product.product}</span><br></br><p class="text-muted">{product.code}<br></br>{product.varient}</p></td>
            <td><span>₹ {Number(product.price).toFixed(2)}</span></td>
            <td><span class="text-body">{product.quantity}</span></td>
            <td><h6 class="mb-0">₹ {Number(product.price * product.quantity).toFixed(2)}</h6></td>
        </tr>
            ))}
          </tbody>
        </table>
        <div class="d-flex justify-content-end align-items-center m-3 mb-2 p-1">
          <div class="order-calculations">
            <div class="d-flex justify-content-between mb-2">
              <span class="w-px-100">Sub Total : </span>
              <span class="text-heading">₹ {Number(process && process[0]?.total).toFixed(2) || 0.00}</span>
            </div>
            <div class="d-flex justify-content-between mb-2">
              <span class="w-px-100">Discount : </span>
              <span class="text-heading mb-0">- ₹ {Number(process && process[0]?.discount).toFixed(2) || 0.00}</span>
            </div>
            <div class="d-flex justify-content-between mb-2">
              <span class="w-px-100">Shipping : </span>
              <span class="text-heading mb-0">₹ {Number(process && process[0]?.shipping).toFixed(2) || 0.00}</span>
            </div>
            <div class="d-flex justify-content-between mb-2">
              <span class="w-px-100">Gift Box : </span>
              <span class="text-heading mb-0">₹ {Number(process && process[0]?.gift).toFixed(2) || 0.00}</span>
            </div>
            <div class="d-flex justify-content-between">
              <h6 class="w-px-100 mb-0"><b>Grand Total : </b></h6>
              <h6 class="mb-0"><b>₹ {Number(process  && process[0]?.grand_total).toFixed(2) || 0.00}</b></h6>
            </div>
          </div>
        </div>
      </div>
    </div>
   
              </div>
              
              <div class="col-md-6 col-lg-4 col-xl-4 order-0 mb-4">
             
                <div class="card pb-4">
                
              
                  {process && process.length > 0 ? (
				  
                  <div class="card-body">
        
      
        <h5 class="mt-3">Tracking Details</h5>
       

                  {process[0].courier==='no_data'?(
                
                    <form onSubmit={handleSubmit}>
                          <div className="row">
                            <div className="mb-3 col-md-12 col-xl-12 col-lg-12">
                               <label htmlFor="link" className="form-label">Tracking ID</label>
					                     <input class="form-control" required name="trackID" value={track} onChange={(e) => settrack(e.target.value)}/>
					                      
                            </div>
                        
                             <div className="mb-3 col-md-12 col-xl-12 col-lg-12">
                               <label htmlFor="link" className="form-label">Courier</label>
					                     <select class="form-control" name="courier" value={courier} onChange={(e) => setcourier(e.target.value)}>
					                       <option selected hidden >Select Courier</option> 
					                       <option value="Blue Dart">Blue Dart</option> 
					                       <option value="FedEx Corp">FedEx Corp</option> 
					                       <option value="ST Courier">ST Courier</option> 
					                       <option value="Time Delivery">Time Delivery</option> 
					                       <option value="DHL Service Point">DHL Service Point</option> 
					                       <option value="Delhivery">Delhivery</option> 
					                       <option value="Indian Postal">Indian Postal</option> 
					                       <option value="DTDC">DTDC</option> 
					                     </select>
                             </div>  
                                
                          </div>     
                            
                         <div className="mt-2 mb-2 col-md-12 col-xl-12 col-lg-12 col-xxl-12 col-12">
                           <button type="submit" className="btn btn-primary me-2 w-100">Submit</button>
                           </div>
                             

                           <h6 align="center">OR</h6>
                             <div className="mb-3 col-md-12 col-xl-12 col-lg-12">
                               <button  class="btn btn-info mt-2 w-100" onClick={stCourier}>Create ST Courier Shipping</button>
                             </div> 

                           </form>
                
         ):(
       
                          <div className="row">
                            <div className=" col-md-12 col-xl-12 col-lg-12">
          <p>Courier : <b>{process && process[0].courier}</b><br>
          </br>Tracking ID :<b> {process && process[0].awno}</b></p>
          </div>
          </div>
          
         )}
         
        <br></br>
        <h5 >Status</h5>
                                       
                                                <div className="row">
                                                    <div className="mb-3 col-md-12 col-xl-12 col-lg-12">
                                                      <label htmlFor="link" className="form-label">Order Status</label>
																		                  <select class="form-control" name="status" value={status} onChange={(e) => setStatus(e.target.value)}>
																			                  <option selected hidden >{process && process[0].status}</option> 
																			                  <option value="Confirm">Confirm</option> 
																			                  <option value="Dispatched">Shipped</option> 
																			                  <option value="Delivered">Delivered</option> 
																			                  <option value="Cancelled">Cancelled</option> 
																			                </select>
                                                    </div>
													
                                                
												
                                                <div className="mt-2 col-md-6 col-xl-6 col-lg-6 col-xxl-6 col-6">
                                                  <button onClick={updatestatus} className="btn btn-primary me-2 w-100">Update</button>
                                                </div>    
												                        <div className="mt-2 col-md-6 col-xl-6 col-lg-6 col-xxl-6 col-6">
	                                                <a href={`invoice?id=${process && process[0].order_id}`} class="btn btn-warning w-100">Invoice</a>
                                                </div>
                                                <div className="mt-2 col-md-12 col-xl-12 col-lg-12 col-xxl-12 col-12">
                                                  <a href={`label?id=${process && process[0].order_id}`} target="_blank" class="btn btn-success w-100 text-white">Print Label</a>
                                                </div>
                                               
                                          </div>
                                      

                                        <div>
         
        </div>                     
        
        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '100%' }}>
      
          {prevOrderId !== null &&
            <a
              href={`order_details?id=${prevOrderId}`}
              className="btn btn-outline-info mt-2 text-sm"
              style={{ borderRadius: '50%', height: '40px', width: '40px', display: 'flex', alignItems: 'center', justifyContent: 'center' }}
            >
              <i className="bx bx-left-arrow-alt bx-sm"></i>
            </a>
          }

          {nextOrderId !== null && 
            <a
            href={`order_details?id=${nextOrderId}`}
              className="btn btn-outline-info mt-2 text-sm"
              style={{ borderRadius: '50%', height: '40px', width: '40px', display: 'flex', alignItems: 'center', justifyContent: 'center' }}
            >
              <i className="bx bx-right-arrow-alt bx-sm"></i>
            </a>
          }
        </div>

      
      
      </div>
      ) : (
        <>
          No orders found
        </>
      )}
        
                </div>
            
              </div>
              </div>


              </div>
  : <div class="container-xxl flex-grow-1 container-p-y">
      <div class="row">
        <div class="col-lg-12">
          <div class="card mb-4" style={{minHeight: '80vh', alignContent: 'center', justifyContent: 'center', alignItems: 'center'}}>
            <p>Loading...</p>
          </div>
        </div>
      </div>
    </div>
    }




          
          <Footer />
          <div className="content-backdrop fade"></div>
        </div>
      </div>
    </div>
    <div className="layout-overlay layout-menu-toggle"></div>
  </div>
  )
}
export default Order_details;
