import React, { useState, useEffect } from 'react';
import Navbar from '../components/nav';
import Aside from '../components/aside';
import Footer from '../components/footer';
import axios from 'axios';
import { useNavigate } from "react-router-dom";
import '../App.css';
import { ReactSession } from 'react-client-session';
ReactSession.setStoreType("localStorage");


 function Addvariations() {
    const userID = ReactSession.get('userId');
    const urlParams = new URLSearchParams(window.location.search);
    const productId = urlParams.get('id');
    const [value, setValue] = useState("");
    const [weights, setWeights] = useState("");
    const [datas, setData] = useState();
    const [price, setPrice] = useState("");
    const navigate = useNavigate();
    const [error, setError] = useState('');
    const [variations, setVariations] = useState([]);
    if(userID <= 0 ){
        navigate('/login');
     }
    
     const [oldvariations, setOldVararations] = useState([{p_id: productId, weights:'Select Mesurement', value:'', price:'', shippingweight:'', height:'', breadth:'', width:'', gst:''}]);

     const handleChangeAdd = () => { 
        const newVar = {p_id: productId, weights:'Select Messurement', value:'', price:'',  mrp:'', p_price:'', shippingweight:'', height:'', breadth:'', width:'', gst:''};
      
        
        setOldVararations([...oldvariations, newVar])
        //console.log(oldvariations);
      }
  
      const handleChangeDelete = (index) => {
        setOldVararations(prevData => { 
          const updatedData = [...prevData.slice(0, index), ...prevData.slice(index + 1)]; 
          return updatedData; 
        });
        
      }

      const handleChangeValue = (e, index) => { 
        const newValue = e.target.value; 
        setOldVararations(prevVariations => { 
          const updatedVariations = [...prevVariations]; 
          updatedVariations[index].value = newValue; 
          return updatedVariations;
          
      }); }

      const handleChangeGst = (e, index) => { 
        const newValue = e.target.value; 
        setOldVararations(prevVariations => { 
          const updatedVariations = [...prevVariations]; 
          updatedVariations[index].gst = newValue; 
          return updatedVariations;
          
      }); }
  
      const handleChangeWeight = (e, index) => { 
          const newValue = e.target.value; 
          setOldVararations(prevVariations => { 
            const updatedVariations = [...prevVariations]; 
            updatedVariations[index].weights = newValue; 
            return updatedVariations;
            
      }); }
  
      const handleChangePrice = (e, index) => { 
            const newValue = e.target.value; 
            setOldVararations(prevVariations => { 
              const updatedVariations = [...prevVariations]; 
              updatedVariations[index].price = newValue; 
              return updatedVariations;
          
      }); }
	  
	  const handleChangemrp = (e, index) => { 
            const newValue = e.target.value; 
            setOldVararations(prevVariations => { 
              const updatedVariations = [...prevVariations]; 
              updatedVariations[index].mrp = newValue; 
              return updatedVariations;
          
      }); }
	  
	  const handleChangepPrice = (e, index) => { 
            const newValue = e.target.value; 
            setOldVararations(prevVariations => { 
              const updatedVariations = [...prevVariations]; 
              updatedVariations[index].p_price = newValue; 
              return updatedVariations;
          
      }); }

      
    const handleweight = (e, index) => {
      const newValue = e.target.value; 
      setOldVararations(prevVariations => { 
        const updatedVariations = [...prevVariations]; 
        updatedVariations[index].shippingweight = newValue; 
        return updatedVariations;
      }); }

  const handleheight = (e, index) => {
    const newValue = e.target.value; 
    setOldVararations(prevVariations => { 
      const updatedVariations = [...prevVariations]; 
      updatedVariations[index].height = newValue; 
      return updatedVariations;
    }); }

  const handlebreadth = (e, index) => {
    const newValue = e.target.value; 
    setOldVararations(prevVariations => { 
      const updatedVariations = [...prevVariations]; 
      updatedVariations[index].breadth = newValue; 
      return updatedVariations;
    }); }

  const handlewidth = (e, index) => {
    const newValue = e.target.value; 
    setOldVararations(prevVariations => { 
      const updatedVariations = [...prevVariations]; 
      updatedVariations[index].width = newValue; 
      return updatedVariations;
    }); }


   
    const openModal = () => {
        const modal = document.getElementById('onloadModal');
        if (modal) {
            modal.classList.add('show');
            modal.style.display = 'block';
            document.body.classList.add('modal-open');
        }
      };
  
    const closeModal = () => {
    const modal = document.getElementById('onloadModal');
      if (modal) {
        modal.classList.remove('show');
        modal.style.display = 'none';
        document.body.classList.remove('modal-open');
      }
    };



  const updateVariation =  async (e) => {
    openModal();
    e.preventDefault();
   // console.log(oldvariations);
    try {
      const response = await axios.post('https://api.arivomkadai.com/editvarients/', {oldvariations});
      if(response.data.status === 'success'){
        navigate(`/offline`);
      }
    } catch (error) {
      console.error('There was a problem with login:', error);
      setError('Invalid email or password');
    }
      closeModal();
  }



  return (
    <div>
      
      <>
        <div id="onloadModal" >
        <div class="modal-content"  align='center'>
            <p style={{padding:'20px'}}>Product Loading.</p>
        </div>
         </div>
        <div className="layout-wrapper layout-content-navbar">
            <div className="layout-container">
                <Aside/>
                <div className="layout-page">
                    <Navbar/>
                    <div className="content-wrapper">


                   




                        <div className="container-xxl flex-grow-1 container-p-y">
                            <h4 className="fw-bold py-3 mb-4"><span className="text-muted fw-light"></span> Add variation</h4>
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="card mb-4">
                                        
                                        <div className="card-body">
                                         

                                        {oldvariations && oldvariations.length > 0 && (
  <>
    {/* Header for Variations */}
    
    
    {oldvariations.map((variation, index) => (
      
      <div key={index}>
       
        <div className="row">
          <div className="mt-3 col-md-2">
            <input
              className="form-control"
              type="text"
              placeholder='variation...'
              value={variation.value}
              onChange={(e) => handleChangeValue(e, index)}
            />
          </div>
          <div className="mt-3 col-md-2">
            <select
              className="form-control"
              value={variation.weights}
              onChange={(e) => handleChangeWeight(e, index)}
            >
              <option value={variation.weights}>{variation.weights}</option>
              <option value="g">g</option>
              <option value="kg">kg</option>
              <option value="l">l</option>
              <option value="ml">ml</option>
              <option value="counts">counts</option>
            </select>
          </div>
          <div className="mt-3 col-md-2">
            <input
              className="form-control"
              type="text"
              placeholder='Selling price...'
              value={variation.price}
              onChange={(e) => handleChangePrice(e, index)}
            />
          </div>
          <div className="mt-3 col-md-2">
            <input
              className="form-control"
              type="text"
              placeholder='MRP...'
              value={variation.mrp}
              onChange={(e) => handleChangemrp(e, index)}
            />
          </div>
          <div className="mt-3 col-md-2">
            <input
              className="form-control"
              type="text"
              placeholder='Purchase price...'
              value={variation.p_price}
              onChange={(e) => handleChangepPrice(e, index)}
            />
          </div>
          <div className="mt-3 col-md-2">
            <input
              className="form-control"
              type="text"
              placeholder='GST %...'
              value={variation.gst}
              onChange={(e) => handleChangeGst(e, index)}
            />
          </div>
         
        </div>

       
       

       
        <div className="row mb-5">
          <div className="mt-3 col-md-2">
            <input
              className="form-control"
              type="text"
              placeholder='Product Breadth...'
              name="breadth"
              value={variation.breadth}
              onChange={(e) => handlebreadth(e, index)}
            />
          </div>
          <div className="mt-3 col-md-2">
            <input
              className="form-control"
              type="text"
              placeholder='Product Width...'
              name="width"
              value={variation.width}
              onChange={(e) => handlewidth(e, index)}
            />
          </div>
          <div className="mt-3 col-md-2">
            <input
              className="form-control"
              type="text"
              placeholder='Product Height...'
              name="height"
              value={variation.height}
              onChange={(e) => handleheight(e, index)}
            />
          </div>
          <div className="mt-3 col-md-2">
            <input
              className="form-control"
              type="text"
              placeholder='Product Weight in KG...'
              name="shippingweight"
              value={variation.shippingweight}
              onChange={(e) => handleweight(e, index)}
            />
          </div>

          <div className="mt-3 col-md-2">
            <button className="btn btn-danger w-100" onClick={() => handleChangeDelete(index)}>
              <i className="bx bx-trash bx-xs me-1"></i>
            </button>
          </div>
        </div>

       


      </div>
      
    ))}
  </>
)}

    <div className="row">
 <div className="mt-3 col-md-4">
    <button class="btn btn-primary btn-sm"  onClick={handleChangeAdd}><i class="bx bx-plus bx-xs me-1"></i>Add New Variation</button>
  </div>
  </div>

  <div className="mt-4" align="center">
                            <button onClick={updateVariation} className="btn btn-primary me-2">Save changes</button>
                          </div>

</div>

                                        
                                                  
                                          
                                            
                                            
                                      
                                    </div>
                                </div>
                            </div>



                         









                        </div>
                        <Footer/>
                        <div className="content-backdrop fade"></div>
                    </div>
                </div>
            </div>
            <div className="layout-overlay layout-menu-toggle"></div>
        </div>
        </>
    </div>
  )
}
export default Addvariations;
