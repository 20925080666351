import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Dashboard from './pages/dashboard';
import Login from './pages/login';
import Product from './pages/product';
import Online from './pages/online';
import Offline from './pages/offline';
import Offlineorder from './pages/offlineorder';
import Onlineorder from './pages/onlineorder';
import Addproduct from './pages/addproduct';
import Customer from './pages/customer';
import Scroll from './pages/scroll';
import Orders from './pages/order_details';
import Offlinescroll from './pages/offlinescroll';
import Editproduct from './pages/editproduct';
import Addofflineproduct from './pages/addoflineproduct';
import Editofflineproduct from './pages/editofflineproduct';
import Invoice from './pages/invoice';
import Giftbox from './pages/offlinegiftbox';
import Preordergiftbox from './pages/preordergiftbox';
import Preorder from './pages/preorder';
import Addpreorderproduct from './pages/addpreorderproduct';
import Editpreorderproduct from './pages/editpreorderproducts';
import Test from './pages/test';
import Preorders from './pages/preorder_order';
import Preordersdet from './pages/preorder_details';
import Addvariations from './pages/addvariations';
import Reportoffline from './pages/reportoffline';
import Reportpreorder from './pages/preorderreport';
import Deliveryoffline from './pages/deliveryoffline';
import Deliverypreorder from './pages/deliverypreorder';
import Couponoffline from './pages/couponoffline';
import Couponpreorder from './pages/couponpreorder';
import Stock from './pages/stockmaintance';
import Editstock from './pages/editstock';
import Testinominal from './pages/testinominal';
import Onlinebanner from './pages/onlinebanner';
import Offlinebanner from './pages/offlinebanner';
import Preorderbanner from './pages/preorderbanner';
import Invoicereport from './pages/invoicereport';
import Itemreport from './pages/itemreport';
import Stockreport from './pages/stockreport';
import Reportofflineinvoice from './pages/reporetoffineinvoice';
import Addvendor from './pages/addvendor';
import Label from './pages/label';
import Vendor from './pages/vendor';
import Vendordetails from './pages/vendor_detail';
import Vendordreport from './pages/vendor_report';
import EditVendor from './pages/editVendor';
import ViewVendor from './pages/viewVendors';



function App() {
  return (
    <Router>
    <Routes>
      <Route path="/" element={<Login />} />
      <Route  path="/login" element={<Login />} />
      <Route  path="/dashboard" element={<Dashboard />} />
      <Route  path="/product" element={<Product />} />
      <Route  path="/online" element={<Online />} />
      <Route  path="/offline" element={<Offline />} />
      <Route  path="/scroll" element={<Scroll />} />
      <Route  path="/order_details" element={<Orders />} />
      <Route  path="/offlinescroll" element={<Offlinescroll />} />
      <Route  path="/offlineorder" element={<Offlineorder />} />
      <Route  path="/onlineorder" element={<Onlineorder />} />
      <Route  path="/customer" element={<Customer />} />
      <Route  path="/addproduct" element={<Addproduct />} />
      <Route  path="/addofflineproduct" element={<Addofflineproduct />} />
      <Route  path="/editofflineproduct" element={<Editofflineproduct />} />
      <Route  path="/editproduct" element={<Editproduct />} />
	    <Route  path="/invoice" element={<Invoice />} />
      <Route  path="/offlinegiftbox" element={<Giftbox />} />
      <Route  path="/preordergiftbox" element={<Preordergiftbox />} />
      <Route  path="/preorder" element={<Preorder />} />
      <Route  path="/addpreorderproduct" element={<Addpreorderproduct />} />
      <Route  path="/editpreorderproducts" element={<Editpreorderproduct />} />
      <Route  path="/test" element={<Test />} />
      <Route  path="/preorder_order" element={<Preorders />} />
      <Route  path="/preorder_details" element={<Preordersdet />} />
      <Route  path="/addvariations" element={<Addvariations />} />
      <Route  path="/reportoffline" element={<Reportoffline />} />
      <Route  path="/preorderreport" element={<Reportpreorder />} />
      <Route  path="/deliveryoffline" element={<Deliveryoffline />} />
      <Route  path="/deliverypreorder" element={<Deliverypreorder />} />
      <Route  path="/couponoffline" element={<Couponoffline />} />
      <Route  path="/couponpreorder" element={<Couponpreorder />} />
      <Route  path="/stockmaintance" element={<Stock />} />
      <Route  path="/editstock" element={<Editstock />} />
      <Route  path="/testinominal" element={<Testinominal />} />
	    <Route  path="/onlinebanner" element={<Onlinebanner />} />
	    <Route  path="/offlinebanner" element={<Offlinebanner />} />
	    <Route  path="/preorderbanner" element={<Preorderbanner />} />
	    <Route  path="/Invoicereport" element={<Invoicereport />} />
	    <Route  path="/itemreport" element={<Itemreport />} />
	    <Route  path="/stockreport" element={<Stockreport />} />
	    <Route  path="/reporetoffineinvoice" element={<Reportofflineinvoice />} />
	    <Route  path="/addvendor" element={<Addvendor />} />
	    <Route  path="/label" element={<Label />} />
	    <Route  path="/vendor" element={<Vendor />} />
	    <Route  path="/vendor_detail" element={<Vendordetails />} />
	    <Route  path="/vendor_report" element={<Vendordreport />} />
	    <Route  path="/edit-vendor" element={<EditVendor />} />
	    <Route  path="/view-vendor" element={<ViewVendor />} />
    </Routes>
  </Router>
  );
}

export default App;
