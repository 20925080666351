import React, { useState, useEffect } from 'react';
import Navbar from '../components/nav';
import Aside from '../components/aside';
import Footer from '../components/footer'; 
import 'react-calendar/dist/Calendar.css';
import axios from 'axios';
import { Paginator } from 'primereact/paginator';
import { useNavigate } from "react-router-dom";
import '../App.css'; 
import { ReactSession } from 'react-client-session'; 
import * as XLSX from 'xlsx';


function Reportoffline() {

  const userID = ReactSession.get('userId');
  const [first, setFirst] = useState(0); 
  const [rows, setRows] = useState(50);
  const [button, setButton] = useState(false); 
  const [order, setOrder] = useState([]);

  const navigate = useNavigate();
    if(userID <= 0 ){
      navigate('/login');
  };

  const fetchData = async () => {
    try {
      const response = await axios.get('https://api.arivomkadai.com/reportoffline/');
	    const paid = response.data.data.filter(item =>item.payment_status ==='paid' && item.status !== 'Cancelled');
      setOrder(paid);
      //console.log(paid);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };


  useEffect(() => {
    fetchData();
  }, []);


  const onPageChange = (event) => {
    setFirst(event.first);
    setRows(50);
  };

  const handleDownloadExcel = () => {
    setFirst(0);
    setRows(filteredDatas.length);
    setButton(true);
    setTimeout(() => {
      downloadExcel();
    }, 1000);
  };

  const downloadExcel = () => {
  
    const input = document.getElementById('order'); 
    const table = input.querySelector('table');
    if (!table) {
      console.error('No table found inside the specified element');
      return;
    }
  
    const ws = XLSX.utils.table_to_sheet(table);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
    XLSX.writeFile(wb, `item_report.xlsx`);

    setFirst(0);
    setRows(15);
    setButton(false);
  };

  const [filterId, setFilterId] = useState('');
  const [filterName, setFilterName] = useState('');
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
 

  const filteredDatas = order.filter(item => {
    const itemDate = new Date(item.date);
    
    const startDateObj = startDate ? new Date(startDate) : null;
    if (startDateObj) {
      startDateObj.setHours(0, 0, 0, 0); 
    }
    const endDateObj = endDate ? new Date(endDate) : null;
    if (endDateObj) {
      endDateObj.setHours(23, 59, 59, 999); 
    }
  
    return (
      (filterId ? item.order_id.includes(filterId) : true) && 
      (filterName ? item.name.toLowerCase().includes(filterName.toLowerCase()) : true) && 
      (startDateObj ? itemDate >= startDateObj : true) && 
      (endDateObj ? itemDate <= endDateObj : true) 
    );
  });


  const openModal = () => {
    const modal = document.getElementById('onloadModal');
    if (modal) {
        modal.classList.add('show');
        modal.style.display = 'block';
        document.body.classList.add('modal-open');
    }
  };
  
  const closeModal = () => {
    const modal = document.getElementById('onloadModal');
    if (modal) {
        modal.classList.remove('show');
        modal.style.display = 'none';
        document.body.classList.remove('modal-open');
    }
  };
  
  useEffect(() => {
    if(filteredDatas && filteredDatas.length === 0 || !filteredDatas){
      openModal();
    }else{
      closeModal();
    }
  },[filteredDatas]);
  

  

  return (
    <>
    <div id="onloadModal">
        <div class="modal-content" align='center'>
            <p style={{padding: '20px'}}>Loading data please wait...</p>
        </div>
    </div>
    <div className="layout-wrapper layout-content-navbar">
        <div className="layout-container">
            <Aside/>
            <div className="layout-page">
                <Navbar/>
                <div className="content-wrapper">
                    <div className="container-xxl flex-grow-1 container-p-y">
                        <div className="row">
                            <div className="col-md-12">
                                <div className="card mb-4">
                                    <h5 className="card-header">Arivomkadai Items Report  
                                      <div  style={{ display: 'flex', justifyContent: 'flex-end' }}>
                                        <button onClick={handleDownloadExcel}  className={`btn ${button === false ? 'btn-primary' : 'btn-secondary'}`} > <i class="fa fa-download me-2"></i> {button === false ? 'Download' : 'Downloading'}</button>
                                      </div>
                                    </h5>
                                    <div className="card-body">
                                                        <div className="row ml-1 mr-1" >
                                                           
                                                            <div className="col-6 col-md-3 col-lg-3 col-xl-3 col-xxl-3 mt-2">
                                                              <input
                                                                type="date"
                                                                className="form-control"
                                                                placeholder="Start date..."
                                                                onChange={(e) => setStartDate(e.target.value)}
                                                              />
                                                            </div>
                                                            <div className="col-6 col-md-3 col-lg-3 col-xl-3 col-xxl-3 mt-2">
                                                              <input
                                                                type="date"
                                                                className="form-control"
                                                                placeholder="End date..."
                                                                onChange={(e) => setEndDate(e.target.value)}
                                                              />
                                                            </div>
                                                            <div className="col-6 col-md-3 col-lg-3 col-xl-3 col-xxl-3 mt-2">
                                                              <input
                                                                type="text"
                                                                className="form-control"
                                                                placeholder="Filter by ID"
                                                                onChange={(e) => setFilterId(e.target.value)}
                                                              />
                                                            </div>
                                                            <div className="col-6 col-md-3 col-lg-3 col-xl-3 col-xxl-3 mt-2">
                                                              <input
                                                                type="text"
                                                                className="form-control"
                                                                placeholder="Filter by Name"
                                                                onChange={(e) => setFilterName(e.target.value)}
                                                              />
                                                            </div>
                                                            
                                                        </div>

                                                        <div className="table-responsive text-nowrap mt-2" id="order">
                                                            <table className="table table-hover">
                                                                <thead>
                                                                    <tr>
                                                                        <th>S.No</th>
                                                                         <th>Order ID</th>
                                                                         <th>Order Date</th>
                                                                         <th>Invoice Number</th>
                                                                         <th>HSN Code</th>
                                                                         <th>Item Code</th>
                                                                         <th>Item Name</th>
                                                                         <th>Item Price</th>
                                                                         <th>GST %</th>
                                                                         <th>GST Amount</th>
                                                                         <th>Item Qty</th>
                                                                         <th>Total Amount</th>
																		                                     <th></th>
                                                                         </tr>
                                                                </thead>
                                                                <tbody className="table-border-bottom-0">
                                                                     {Array.isArray(filteredDatas) && filteredDatas.slice(first, first + rows).map((orders, index)=> 
                                                                        orders.orderData.map(items =>
                                                                          <tr key={index}>
                                                                          <td>{index+first+1}</td>
                                                                          <td>{items.id}</td>
                                                                          <td>{orders.date}</td>
                                                                          <td>#000{orders.invoiceNumber}</td>
                                                                          <td></td>
                                                                          <td>{items.code}</td>
                                                                          <td>{items.product}</td>
                                                                          <td>{Number(items.price / ((items.gst/100)+1)).toFixed(2)}</td>
                                                                          <td>{items.gst}</td>
                                                                          <td>{ Number(items.price-(items.price / ((items.gst/100)+1))).toFixed(2)}</td>
                                                                          <td>{items.quantity}</td>
                                                                          <td>{Number(items.price*items.quantity).toFixed(2)}</td>
                                                                        </tr> 
                                                                       )
                                                                      )}
                                                                </tbody>
                                                            </table>
                                                            <Paginator className="custom-paginator" first={first} rows={rows} totalRecords={filteredDatas ? filteredDatas.length : 0} onPageChange={onPageChange} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <Footer/>
                    <div className="content-backdrop fade"></div>
                </div>
            </div>
        </div>
        <div className="layout-overlay layout-menu-toggle"></div>
    </div>
    </>
  )
}

export default Reportoffline;
